export const useFormatPrice = () => {
  const { t } = useI18n()

  const formatPrice = (num: number | string, currency: string = 'USD') => {
    if (typeof num === 'string') {
      num = Number(num)
    }

    if (Number.isNaN(num)) {
      return '0'
    }

    const formattedNumber = Math.abs(num).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })

    return `${num < 0 ? '-' : ''}${t(`symbols.${currency}`)}${formattedNumber}`
  }

  return { formatPrice }
}
